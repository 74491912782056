<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcCreateNonMember }}</h1>
      <b-form validated>
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <div>
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-1" :label="`${translations.tcGender}:`" label-for="gender-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="gender-select" :title="translations.tcGender" :options="gender_list"
                          v-model="nonMember.gender_key" class="g-select flex-0 mr-3" required></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="nonMember.gender_key === 'CBD33F42-B5CA-475E-9E26-F05047365C20'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-1" :label="`${translations.tcPrefix}:`" label-for="prefix-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="prefix-select" :title="translations.tcPrefix" :options="optionsPrefixFemale"
                          v-model="nonMember.prefix_key" class="g-select flex-0 mr-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="nonMember.gender_key === 'B25E4B70-3F72-48CF-B21E-61A860B36C6A'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-1" :label="`${translations.tcPrefix}:`" label-for="prefix-m-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="prefix-m-select" :title="translations.tcPrefix" :options="optionsPrefixMale"
                          v-model="nonMember.prefix_key" class="g-select flex-0 mr-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-1" :label="`${translations.tcFirstName}:`"
                        label-for="first-name-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcFirstName" id="first-name-input"
                          :placeholder="translations.tcFirstName" v-model="nonMember.first_name" required
                          trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-2" :label="`${translations.tcMiddleName}:`"
                        label-for="middle-name-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcMiddleName" id="middle-name-input"
                          :placeholder="translations.tcMiddleName" v-model="nonMember.middle_name" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-3" :label="`${translations.tcLastName}:`"
                        label-for="last-name-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcLastName" id="last-name-input"
                          :placeholder="translations.tcLastName" v-model="nonMember.last_name" required
                          trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="nonMember.gender_key === 'B25E4B70-3F72-48CF-B21E-61A860B36C6A'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-2" :label="`${translations.tcSuffix}:`" label-for="suffix-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="suffix-select" :title="translations.tcSuffix" :options="optionsSuffix"
                          v-model="nonMember.suffix_key" class="g-select flex-0 mr-3 mb-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-5" :label="`${translations.tcInformalName}:`"
                        label-for="informal-name-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcInformalName" id="informal-name-input"
                          :placeholder="translations.tcInformalName" v-model="nonMember.informal_name"
                          trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-3" :label="`${translations.tcPreferredName}:`"
                        label-for="preferred-select" label-class="form-group-label" class="form-element">
                        <b-form-select id="preferred-select" :title="translations.tcPreferredName"
                          :options="optionsPreferredName" v-model="nonMember.preferred_name"
                          class="g-select flex-0 mr-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-3" :label="`${translations.tcEmail}:`" label-for="email-input"
                        label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcEmail" id="email-input" :placeholder="translations.tcEmail"
                          v-model="nonMember.email" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-3" :label="`${translations.tcPhone}:`" label-for="phone-input"
                        label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcPhone" id="phone-input" :placeholder="translations.tcPhone"
                          v-model="nonMember.phone" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-addr1" :label="`${translations.tcAddress1}:`"
                        label-for="address1-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcAddress1" id="address1-input"
                          :placeholder="translations.tcAddress1" v-model="nonMember.address1" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-addr2" :label="`${translations.tcAddress2}:`"
                        label-for="address2-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcAddress2" id="address2-input"
                          :placeholder="translations.tcAddress2" v-model="nonMember.address2" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-city" :label="`${translations.tcCity}:`" label-for="city-input"
                        label-class="form-group-label" class="form-element">
                        <b-form-input :title="translations.tcCity" id="city-input" :placeholder="translations.tcCity"
                          v-model="nonMember.city" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="nonMember.cnt_key === usCountry">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-state" :label="`${translations.tcState}:`" label-for="state-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="state-select" :title="translations.tcState" :options="optionsStates"
                          v-model="nonMember.state" class="g-select flex-0 mr-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="nonMember.cnt_key !== usCountry">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-state-province" :label="`${translations.tcStateOrProvince}:`"
                        label-for="state-province-select" label-class="form-group-label" class="form-element">
                        <b-form-input :title="`${translations.tcStateOrProvince}:`" id="state-province-input"
                          :placeholder="`${translations.tcStateOrProvince}:`" v-model="nonMember.state"
                          trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-postal-code" :label="`${translations.tcPostalCode}:`"
                        label-for="postal-code-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="`${translations.tcPostalCode}:`" id="postal-code-input"
                          :placeholder="`${translations.tcPostalCode}:`" v-model="nonMember.postal_code"
                          trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-country" :label="`${translations.tcCountry}:`"
                        label-for="country-select" label-class="form-group-label" class="form-element">
                        <b-form-select id="country-select" :title="translations.tcCountry" :options="optionsCountries"
                          v-model="nonMember.cnt_key" class="g-select flex-0 mr-3 mb-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary" @click="handleSaveClick()" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcSave }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageBody from '@/components/page-components/PageBody.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'testimony-speaker-create-non-member',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      trim: true,
      phone_type: 'constant_pht_key_home', //'7624F304-AE77-4185-89DC-8ADF63E7E1F7',
      address_type: 'constant_adt_key_mailing', //'D6A4A54B-B7C9-4629-B7C8-0AE267DA612A',
      email_type: 'constant_emt_key_home', //'D5BE37A2-D7A4-4FF5-87E0-EDBE263F337F',
      usCountry: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      gender_list: [
        { value: 'B25E4B70-3F72-48CF-B21E-61A860B36C6A', text: 'Male' },
        { value: 'CBD33F42-B5CA-475E-9E26-F05047365C20', text: 'Female' },
      ],
      nonMember: {
        gender_key: 'CBD33F42-B5CA-475E-9E26-F05047365C20',
        prefix_key: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        suffix_key: null,
        informal_name: '',
        preferred_name: 'F',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: null,
        postal_code: '',
        cnt_key: null, //'bc4b70f8-280e-4bb0-b935-9f728c50e183'
      },
    }
  },
  methods: {
    ...mapActions({
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadIndividualEditFormOptions: 'membership/loadIndividualEditFormOptions',
      loadSpouseFormOptions: 'membership/loadSpouseFormOptions',
      loadUsStates: 'membership/loadUsStates',
      saveNonMember: 'membership/saveNonMember',
      setLoadingStatus: 'menu/setLoadingStatus',
      setNewNonMemberTestimonySpeaker: 'user/setNewNonMemberTestimonySpeaker',
      setRouterBackLink: 'user/setRouterBackLink',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getViewTranslations(),
          await this.loadSpouseFormOptions(),
          await this.loadAddressFormOptions(),
          await this.loadUsStates()
        ]).then(() => {
          this.gender_list[0].text = this.translations.tcMale
          this.gender_list[1].text = this.translations.tcFemale
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleCancelClick() {
      this.$router.go(-1)
    },
    async handleSaveClick() {
      try {
        this.setLoadingStatus(true)
        if (this.nonMember.first_name === '' || this.nonMember.last_name === '') {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorFirstLastNamesRequired,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            return
          })
        } else {
          var payload = {
            gender: this.nonMember.gender_key,
            user_ind_key: this.userIndKey,
            prefix_key: this.nonMember.prefix_key,
            first_name: this.nonMember.first_name,
            middle_name: this.nonMember.middle_name,
            last_name: this.nonMember.last_name,
            suffix_key: this.nonMember.suffix_key,
            informal_name: this.nonMember.informal_name,
            preferred_name: this.nonMember.preferred_name,
            email: this.nonMember.email,
            phone: this.nonMember.phone,
            address1: this.nonMember.address1,
            address2: this.nonMember.address2,
            city: this.nonMember.city,
            state: this.nonMember.state,
            postal_code: this.nonMember.postal_code,
            cnt_key: this.nonMember.cnt_key,
            org_key: this.officerSelectStatekey.country_state,
          }
          let result = true
          await Promise.all([(result = await this.saveNonMember(payload))]).then(() => {
            if (!result.data || result.data.length < 36) {
              throw result.data
            } else {
              this.setNewNonMemberTestimonySpeaker({ text: payload.first_name + ' ' + payload.last_name, value: result.data })
            }
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.translations.tcIndividualWasSaved : this.translations.tcErrorOccurred,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then((result) => {
              if (result.value) {
                this.setRouterBackLink('')
                this.$router.go(-1)
              }
            })
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    await this.pageLoad()
  },
  computed: {
    ...mapGetters({
      addressFormOptions: 'membership/addressFormOptions',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      convertedSpouseFormOptions: 'membership/convertedSpouseFormOptions',
      convertedUsStates: 'membership/convertedUsStates',
      officerSelectStatekey: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      prospectProfile: 'membership/prospectProfile',
      spouseFormOptions: 'membership/spouseFormOptions',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      usStates: 'membership/usStates',
    }),
    optionsPrefixFemale() {
      return [
        { value: null, text: this.translations.tcSelectPrefix, disabled: true },
        ...this.convertedSpouseFormOptions.prefixFemale,
      ]
    },
    optionsPrefixMale() {
      return [
        { value: null, text: this.translations.tcSelectPrefix, disabled: true },
        ...this.convertedSpouseFormOptions.prefixMale,
      ]
    },
    optionsSuffix() {
      return [
        { value: null, text: this.translations.tcSelectSuffix, disabled: true },
        ...this.convertedSpouseFormOptions.suffixMale,
      ]
    },
    optionsCountries() {
      return [
        { value: null, text: this.translations.tcSelectCountry, disabled: true },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    optionsStates() {
      return [{ value: null, text: this.translations.tcSelectState, disabled: true }, ...this.convertedUsStates]
    },
    optionsPreferredName() {
      return [
        { text: this.translations.tcFormal, value: 'F' },
        { text: this.translations.tcInformal, value: 'I' },
      ]
    },
  },
  components: {
    pageBody: pageBody,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.form-group-label {
  margin-bottom: 0;
  color: #636363;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
}

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
